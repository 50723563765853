import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Duennwald: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Duennwald,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Dünnwald"
          d="M682.4,294.4c5.3,2.2,5.2,6.3,6.5,11c3.3-0.5,7.8-1.4,11.1-2.1l5.4,7.1c3.3-2.3,7.8-4.9,11.1-7.2 c8.9-0.8,19.6-1.3,28.4-2.5c3.1-3.5,8.6-8.2,12.1-11.2c4.9,10.1,10.4,7.9,19.9,8.4c7.7-0.6,16.6,3.4,24.2,1.4l1.5,3.4 c2.1-1,6.6-3.7,8.7-4.9c0.1,0.1,2.6-0.6,2.8-0.6c0,0,3.4,0.2,3.4,0.2v0.5c3.4,0.9,8.5,0.4,11.5,2.3c-0.1,0.3-0.7,1-0.9,1.2 c0.6,1.2,1.5,3.7,2.5,4.6c-2.2,2.1-6.9,6.8-9,9c-2.9,1.8-7.2,4.5-9.8,6.7c12.2,15.8,23.1,33.9,37.8,47.6l-1.3,4.1 c-9.4,6.2-18.7,20.4-23.1,30.7L778,440l-6-22l-28-16l-20,12l-10-18l-38,8l10-58l-5.7-53.3c0.2,0.2,0.6,1.2,0.7,1.4 C681.4,294,682.1,294.2,682.4,294.4z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 730.1998 361.4086)"
        >
          Dünnwald
        </text>
      </g>
    </>
  );
});
